import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Img from "gatsby-image";
import { css, Global } from "@emotion/core";

import resolveConfig from "tailwindcss/resolveConfig";
const tailwindConfig = require("../../tailwind.config");

const fullConfig = resolveConfig(tailwindConfig);
const green900 = fullConfig.theme.colors.green["900"];

const StoryPage = ({ data }) => {
  return (
    <Layout>
      <SEO title={data.file.childMarkdownRemark.frontmatter.title} />
      <Global
        styles={css`
          body {
            background-color: ${green900};
          }
        `}
      />
      <div className={"bg-green-900 relative"}>
        <Img
          fluid={{
            ...data.file.childMarkdownRemark.frontmatter.featured_image
              .childImageSharp.fluid,
            aspectRatio: 2.5,
          }}
          alt={data.file.childMarkdownRemark.frontmatter.title}
          css={css`
            z-index: 1;
          `}
        />
        <div
          className={
            "px-2 py-4 md:px-4 md:py-6 mx-auto bg-yellow-500 text-white antialiased -mt-2 md:-mt-8 rounded-lg shadow-lg"
          }
          css={css`
            z-index: 2;
            position: relative;
            max-width: 90vw;
            width: 70rem;
          `}
        >
          <h1
            className={
              "text-xl sm:text-3xl md:text-5xl lg:text-6xl leading-none font-semibold text-white text-center"
            }
          >
            {data.file.childMarkdownRemark.frontmatter.title}
          </h1>
          <p className={"text-center text-base md:text-lg"}>
            {data.file.childMarkdownRemark.frontmatter.date_published}
          </p>
          <p className={"text-center text-xs md:text-sm italic"}>
            Time to read: {data.file.childMarkdownRemark.timeToRead} minute(s)
          </p>
        </div>
        <div
          className={
            "mt-4 md:mt-8 p-6 md:p-8 space-y-6 max-w-2xl mx-auto text-base md:text-lg text-white font-light"
          }
          dangerouslySetInnerHTML={{
            __html: data.file.childMarkdownRemark.html,
          }}
        />
        <div className={"pb-8"} />
      </div>
    </Layout>
  );
};

export const StoryPageQuery = graphql`
  query storyPage($id: String!) {
    file(id: { eq: $id }) {
      id
      childMarkdownRemark {
        frontmatter {
          title
          date_published(formatString: "MMMM Do, YYYY")
          featured_image {
            childImageSharp {
              fluid(maxWidth: 1900, cropFocus: ATTENTION) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        html
        timeToRead
      }
    }
  }
`;

export default StoryPage;
